import styled from 'styled-components';

const SpecialTextStyles = styled.span`
  font-family: 'clone-rounded-latin', sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15),
    -1px -1px 2px rgba(255, 255, 255, 0.15);
  font-size: ${props => props.theme.fontSize(2)};
  color: ${props => props.theme.fgColor};
  flex: 2;
`;

export default SpecialTextStyles;
