import React from 'react'

import SpecialText from './SpecialText';

const BoraBlaast = (props) => (
  <SpecialText>
    <p>bora blaast<br/>
      bora raast<br/>
      fluisterzacht intiem<br/>
      oorstrelend spektakel</p>
    {props.children}
  </SpecialText>
);

export default BoraBlaast
