import React from 'react';

import BoraPage from '../components/BoraPage';
import BoraBlaast from '../components/BoraBlaast';

export default () => (
  <BoraPage pageName="Home" pageTitle="Welkom bij Bora" slug="">
    <BoraBlaast/>
  </BoraPage>
);
